declare var process:any;

class FetchProvider {
  public async fetch(args: {path: RequestInfo, options?: RequestInit}): Promise<Response> {
    // Wrapper class for fetch so we can implement global standards if necessary
    const { path, options } = args;

    const opts = {
      ...options,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let url = `https://www.buildbettersoftware.com/api/v1${path}`;
    if ( process.env.NODE_SETTINGS_ENV === 'staging' ) {
      url = `https://reenhanced-dev.azurewebsites.net/api/v1${path}`;
    } else {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        url = `http://localhost:8010/api/v1${path}`;
      }
    }

    const response = await fetch(url, opts)
    return response;
  }
}

export const fetchProvider = new FetchProvider();
export default fetchProvider;