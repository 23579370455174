import { all, fork } from "redux-saga/effects";
import { hydrateUser } from "./authSagas";
import { watchSubscriptions, fetchSubscriptionConfig, watchResetSubscriptionSites } from "./subscriptionSagas";

export const rootSaga = function* root() {
  yield all([
		hydrateUser(),
    fetchSubscriptionConfig(),
    watchSubscriptions(),
    watchResetSubscriptionSites()
  ]);
}
