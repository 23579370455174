import { call, put, select, takeLatest } from "redux-saga/effects";
import fetchProvider from "../providers/fetchProvider";
import { RootState } from "../reducers";
import { loaded, loading, configLoaded, refreshSubscription, resetSubscriptionSites } from "../reducers/subscription";
import { ISubscription } from "../types";

function* fetchSubscriptions() {
	let payload = { subscriptions: [] }

	const response = yield call({context: fetchProvider, fn: fetchProvider.fetch}, {path: `/subscriptions`});
	if (response.status === 200) {
		const json = yield call([response, response.json]);
		payload.subscriptions = json.subscriptions;
	}

	yield put(loaded(payload));
}

function* deleteSubscriptionSites(action) {
	const subscription = action.payload;

	const response = yield call({context: fetchProvider, fn: fetchProvider.fetch}, {path: `/subscriptions/${subscription.id}/domains`, options: { method: 'DELETE' }});
	if (response.status === 200) {
		const json = yield call([response, response.json]);

		yield put(refreshSubscription(json));
	}
}

export function* watchResetSubscriptionSites() {
	yield takeLatest(resetSubscriptionSites, deleteSubscriptionSites);
}


export function * fetchSubscriptionConfig() {
	const defaults = yield select((s: RootState) => s.subscription);
	let payload = {
		config: {
			subscriptionUrl: defaults.subscriptionUrl,
			prices: defaults.prices
		}
	};

	const response = yield call({context: fetchProvider, fn: fetchProvider.fetch}, {path: `/config/subscriptions`});
	if (response.status === 200) {
		const json = yield call([response, response.json]);
		payload = json;
	}

	yield put(configLoaded(payload));
}

export function* watchSubscriptions() {
	yield takeLatest(loading, fetchSubscriptions)
}