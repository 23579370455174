import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../sagas';
import rootReducer from '../reducers';
import { configureStore } from '@reduxjs/toolkit';

const sagas = createSagaMiddleware();

export const store = configureStore({
	reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ thunk: true, serializableCheck: false }), sagas],
});

sagas.run(rootSaga);

export type AppDispatch = typeof store.dispatch

export default store;