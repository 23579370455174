import { call, put } from "redux-saga/effects";
import fetchProvider from "../providers/fetchProvider";
import { authenticated, loading } from "../reducers/auth";

export function* hydrateUser() {
	let payload = {
		user: undefined,
		error: ''
	};

	yield put(loading);

	const response = yield call({context: fetchProvider, fn: fetchProvider.fetch}, {path: `/me`});
	if (response.status === 200) {
		const json = yield call([response, response.json]);

		if (json && json.user) {
			payload.user = json.user;
		}
	} else {
		payload.error = response.statusText;
	}

	yield put(authenticated(payload));
}