// Run this example by adding <%= javascript_pack_tag 'app' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from '../components/App';
import history from "../src/history";
import { Router } from 'react-router-dom'

import store from '../src/store';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>, document.body.appendChild(document.createElement('div')));
});